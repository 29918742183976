import React, { useContext, useEffect, useState } from "react";
import Filters from "./Filters";
import ProductCard from "./ProductCard";
import { MainContext } from "../../context/MainContext";
import Loader from "../Loader";

const ProductsWrapper = () => {
  const { products, selectedCat, price } = useContext(MainContext);
  const [cat, setCat] = useState();

  useEffect(() => {
    setCat(selectedCat);
  }, [selectedCat]);

  return (
    <div className="wrapper productsWrapper">
      <h1 className="productsTitle">Products</h1>
      <Filters />

      <div className="productsGrid">
        {products ? (
          cat !== "All" ? (
            products
              .filter((product) => product.show === true)
              .filter(
                (product) =>
                  (product.category && product.category._id === selectedCat) ||
                  (product.subcategory &&
                    product.subcategory._id === selectedCat)
              )

              .map((product, i) => {
                return (
                  <ProductCard
                    image={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${product.images[0]}`}
                    price={product.price + price}
                    title={product.title}
                    link={`/products/${product._id}`}
                    key={i}
                  />
                );
              })
          ) : (
            products
              .filter((product) => product.show === true)

              .map((product, i) => {
                return (
                  <ProductCard
                    image={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${product.images[0]} `}
                    price={product.price + price}
                    title={product.title}
                    link={`/products/${product._id}`}
                    key={i}
                  />
                );
              })
          )
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default ProductsWrapper;
